import { Typography } from '@mui/material';
import { useGetBookingQr } from 'apis-query/booking.query';
import SuiBox from 'components/SuiBox';
import SuiModal from 'components/SuiModal';
import { format } from 'date-fns/esm';
import useCopyImageToClipboard from 'pages/schedules/sales/hooks/useCopyImageToClipboard';
import { useEffect } from 'react';
import theme from 'shared/theme';

export default function ModalBookingQr({
  bookingId,
  onClose,
}: {
  bookingId: string;
  onClose?: () => void;
}) {
  const { data, isLoading, isFetching, isFetched, error } =
    useGetBookingQr(bookingId);
  const { isCopied, copyImage } = useCopyImageToClipboard();

  useEffect(() => {
    if (isCopied) return;
    if (isFetched && data?.data?.qrDataURL) {
      copyImage(data?.data?.qrDataURL);
    }
  }, [isCopied, data?.data?.qrDataURL, isFetched]);

  return (
    <SuiModal
      showCloseIcon={false}
      loading={isLoading || isFetching}
      confirmDisabled={isLoading || isFetching || !data?.data?.qrDataURL}
      backText="Thoát"
      open={true}
      onClose={() => {
        onClose && onClose();
      }}
      sx={{
        h4: {
          width: '100%',
        },
      }}
      confirmText={'Tải QR'}
      cancelText={'Đóng'}
      onConfirm={() => {
        const qrCode = data?.data?.qrDataURL;
        if (qrCode) {
          copyImage(qrCode);
          const link = document.createElement('a');
          link.href = qrCode;
          link.download = `${bookingId}_${format(
            new Date(),
            'dd/MM/yyyy'
          )}.png`;
          link.click();
        }
        onClose && onClose();
      }}
      onCancel={() => {
        onClose && onClose();
      }}
      title={
        <SuiBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography
            sx={{
              fontSize: {
                xs: '1.3rem',
                sm: '1.6rem',
                md: '2rem',
              },
            }}
            fontWeight="bold"
          >
            Thanh toán QR
          </Typography>
        </SuiBox>
      }
      desktopHeight="80dvh"
      desktopWidth={theme.spacing(100)}
    >
      <>
        {data?.data?.qrDataURL && (
          <img
            src={data?.data?.qrDataURL}
            alt=""
            style={{
              width: '100%',
              objectFit: 'contain',
              maxHeight: '90%',
            }}
          />
        )}

        {error && (
          <SuiBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            height="100%"
          >
            <Typography color="error">
              {' '}
              {(error as any)?.response?.data?.message ||
                (error as any)?.message}
            </Typography>
          </SuiBox>
        )}
      </>
    </SuiModal>
  );
}
