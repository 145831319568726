import { useState } from 'react';

const useCopyImageToClipboard = () => {
  const [isCopied, setIsCopied] = useState(false);

  const copyImage = async (base64Image: string) => {
    try {
      // Convert the base64 string to a Blob
      const byteCharacters = atob(base64Image.split(',')[1]); // Removing the "data:image/png;base64," part
      const byteArrays = new Uint8Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays[i] = byteCharacters.charCodeAt(i);
      }

      const blob = new Blob([byteArrays], { type: 'image/png' }); // Can also change type to match image type

      // Create a clipboard item and write it to the clipboard
      const item = new ClipboardItem({ 'image/png': blob });
      await navigator.clipboard.write([item]);

      // Set copied state to true
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Reset copied state after 2 seconds
    } catch (error) {
      console.error('Failed to copy image: ', error);
      setIsCopied(false);
    }
  };

  return { isCopied, copyImage };
};

export default useCopyImageToClipboard;
