import {
  useQuery,
  UseMutationOptions,
  useMutation,
} from '@tanstack/react-query';
import {
  createBooking,
  updateBooking,
  getBooking,
  getListBooking,
  getListChildRoomCanReplace,
  replaceChildRoomInOrder,
  getBookingQr,
} from 'apis/booking.api';
import {
  ApiError,
  ApiResponseList,
  BaseResponse,
} from 'interfaces/api-response.interface';
import { PageOptions } from 'interfaces/page-options.interface';

import {
  Booking,
  CreateBooking,
  UpdateBooking,
} from 'shared/models/booking.model';
import useMylaMutation, {
  UseMylaMutationOptions,
} from 'shared/packages/myla-query/hooks/useMutation';
import { ChildRoom } from '../shared/models';

export const BOOING_QUERY_KEY = {
  TOTAL_BOOKING_PRICE: 'TOTAL_BOOKING_PRICE',
  GET_BOOKING: 'GET_BOOKING',
  GET_LIST_BOOKING: 'GET_LIST_BOOKING',
  GET_LIST_CHILD_ROOM_CAN_REPLACE: 'GET_LIST_CHILD_ROOM_CAN_REPLACE',
  GET_BOOKING_QR: 'GET_BOOKING_QR',
};

export const useCreateBooking = (
  options: Omit<
    UseMutationOptions<Booking, ApiError, CreateBooking, unknown>,
    'mutationFn'
  >
) =>
  useMutation<Booking, ApiError, CreateBooking>({
    ...options,
    mutationFn: (values) => createBooking(values),
  });

export const useUpdateBooking = (
  options: Omit<
    UseMylaMutationOptions<Booking, ApiError, UpdateBooking, unknown>,
    'mutationFn'
  >
) =>
  useMylaMutation<Booking, ApiError, UpdateBooking>({
    ...options,
    mutationFn: (values) => updateBooking(values),
  });

export const useGetBooking = (id: string, option: PageOptions) =>
  useQuery<Booking>({
    queryKey: [BOOING_QUERY_KEY.GET_BOOKING, id],
    queryFn: () => getBooking(id, option),
    refetchOnWindowFocus: false,
    enabled: !!id,
    keepPreviousData: false,
  });

export const useGetListBooking = (option: PageOptions) =>
  useQuery<ApiResponseList<Booking>>({
    queryKey: [BOOING_QUERY_KEY.GET_LIST_BOOKING, option],
    queryFn: () => getListBooking(option),
    refetchOnWindowFocus: false,
  });

export const useGetListChildRoomCanReplace = (
  orderId: string,
  enabled: boolean
) =>
  useQuery<ChildRoom[]>({
    queryKey: [BOOING_QUERY_KEY.GET_LIST_CHILD_ROOM_CAN_REPLACE, orderId],
    queryFn: () => getListChildRoomCanReplace(orderId),
    enabled: !!orderId && enabled,
  });

export const useReplaceChildRoomOrder = (
  options: Omit<
    UseMylaMutationOptions<
      BaseResponse,
      ApiError,
      {
        childRoomId: string;
        orderId: string;
      },
      unknown
    >,
    'mutationFn'
  >
) =>
  useMylaMutation<
    BaseResponse,
    ApiError,
    {
      childRoomId: string;
      orderId: string;
    }
  >({
    ...options,
    mutationFn: ({ childRoomId, orderId }) =>
      replaceChildRoomInOrder(orderId, childRoomId),
  });

export const useGetBookingQr = (id: string) =>
  useQuery<{
    code: string;
    desc: string;
    data: {
      qrDataURL: string;
    };
  }>({
    queryKey: [BOOING_QUERY_KEY.GET_BOOKING_QR, id],
    queryFn: () => getBookingQr(id),
    refetchOnWindowFocus: false,
    enabled: !!id,
    keepPreviousData: false,
  });
