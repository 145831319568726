import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PhoneIcon from '@mui/icons-material/Phone';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ScheduleIcon from '@mui/icons-material/Schedule';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  IconButtonProps,
  Avatar,
  styled,
  Icon,
} from '@mui/material';
import SuiBox from 'components/SuiBox';
import SuiButton from 'components/SuiButton';
import SuiTypography from 'components/SuiTypography';
import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import { Booking } from 'shared/models/booking.model';
import { OrderBooking } from 'shared/models/orderBooking.model';
import { getUserPhotoUrl } from 'shared/utils/getUserPhotoUrl';
import { useCopyToClipboard } from 'shared/hooks/useCopyToClipboard';
import { useNotifyToast } from 'shared/packages/myla-query/hooks/useNotifyToast';
import { NotifyStatus } from 'shared/packages/myla-query/types';
import BookingForm from '../sales/components/BookingForm';
import StatusTag from '../sales/components/BookingForm/StatusTag';
import OrderForm from '../sales/components/OrderForm';
import OrderHistory from './OrderHistory';
import ModalBookingQr from '../sale-history/components/ModalBookingQr';
import { BookingStatus } from 'shared/enums';

interface BookingHistoryProps {
  booking: Booking;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton color="primary" {...other} size="large" />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function BookingHistory({ booking }: BookingHistoryProps) {
  const [isShowModalBooking, setIsShowModalBooking] = useState(false);
  const [bookingIdSelected, setBookingIdSelected] = useState('');
  const [isShowModalOrder, setIsShowModalOrder] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderBooking>();
  const [expanded, setExpanded] = useState(false);
  const { copyFn } = useCopyToClipboard();
  const { showToast } = useNotifyToast();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleCopyBookingCode = useCallback((bookingCode: string) => {
    copyFn(bookingCode);
    showToast({
      notifyStatus: NotifyStatus.SUCCESS,
      toastContent: {
        message: `Mã booking đã được copy: ${bookingCode}`,
      },
    });
  }, []);

  return (
    <Card sx={{ py: 2 }} key={booking._id}>
      {bookingIdSelected && (
        <ModalBookingQr
          bookingId={booking._id}
          onClose={() => setBookingIdSelected('')}
        />
      )}
      <CardHeader
        sx={{
          borderBottom: '1px solid #F2F2F2',
          px: {
            xs: 2,
            md: 3,
          },
        }}
        avatar={<Avatar src={getUserPhotoUrl(booking?.createdBy)} />}
        action={
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon color="primary" />
          </ExpandMore>
        }
        title={booking?.createdBy?.name}
        subheader={
          booking.createdAt &&
          format(new Date(booking.createdAt), 'dd/MM/yyyy HH:mm:ss')
        }
      />
      <CardContent
        sx={{
          pb: 0,
          px: {
            xs: 2,
            md: 3,
          },
        }}
      >
        <SuiBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <SuiTypography variant="p" fontSize="1.125rem" fontWeight="medium">
            #
            <SuiTypography
              variant="span"
              fontSize="1.125rem"
              fontWeight="medium"
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => handleCopyBookingCode(booking?.bookingCode)}
            >
              {booking?.bookingCode || booking._id}
            </SuiTypography>
          </SuiTypography>

          <StatusTag noBorder status={booking.status} />
        </SuiBox>
        <SuiBox
          color="#4F4F4F"
          display="flex"
          alignItems="center"
          columnGap={1}
          mb={0.5}
        >
          <SuiTypography variant="p" fontSize="1rem" textColor="inherit">
            {booking?.distributor?.name}
          </SuiTypography>
          <SuiBox
            backgroundColor="#D9D9D9"
            width="0.25rem"
            height="0.25rem"
            borderRadius="50%"
          />
          <SuiTypography variant="p" fontSize="1rem" textColor="inherit">
            {booking?.unit?.name}
          </SuiTypography>
        </SuiBox>
        <SuiBox
          display="flex"
          alignItems="center"
          color="#4F4F4F"
          mb={0.5}
          columnGap={1}
        >
          <CalendarTodayIcon />
          <SuiTypography variant="p" fontSize="1rem" textColor="inherit">
            {format(new Date(booking.expectCheckInDate), 'dd')}&nbsp;-&nbsp;
            {format(new Date(booking.expectCheckOutDate), 'dd/MM/yyyy')}
          </SuiTypography>
        </SuiBox>
        <SuiBox
          display="flex"
          alignItems="center"
          color="#4F4F4F"
          mb={0.5}
          columnGap={1}
        >
          <SuiBox
            display="flex"
            alignItems="center"
            color="#4F4F4F"
            columnGap={1}
          >
            <PersonOutlineIcon />
            <SuiTypography variant="p" fontSize="1rem" textColor="inherit">
              {booking?.customer?.name}
            </SuiTypography>
          </SuiBox>
          -
          <SuiBox
            display="flex"
            alignItems="center"
            color="#4F4F4F"
            columnGap={1}
            component="a"
            href={`tel:${booking?.customer?.phone}`}
          >
            <PhoneIcon />
            <SuiTypography variant="p" fontSize="1rem" textColor="inherit">
              {booking?.customer?.phone}
            </SuiTypography>
          </SuiBox>
        </SuiBox>
        <SuiBox
          display="flex"
          alignItems="center"
          color="#4F4F4F"
          columnGap={1}
        >
          <ScheduleIcon />
          <SuiTypography variant="p" fontSize="1rem" textColor="inherit">
            {booking.createdAt &&
              format(new Date(booking.createdAt), 'dd/MM/yyyy HH:mm:ss')}
          </SuiTypography>
        </SuiBox>
      </CardContent>

      <CardActions
        disableSpacing
        sx={{
          px: {
            xs: 2,
            md: 3,
          },
          justifyContent: 'space-between',
        }}
      >
        <SuiBox
          display="flex"
          columnGap={1}
          justifyContent="flex-end"
          width="100%"
          alignItems="center"
          mt={1}
        >
          {booking.status === BookingStatus.NEW && (
            <SuiButton
              iconOnly
              variant="outlined"
              buttonColor="primary"
              onClick={(e: React.MouseEvent) => {
                setBookingIdSelected(booking._id);
              }}
            >
              <Icon>qr_code</Icon>
            </SuiButton>
          )}
          <SuiButton
            iconOnly
            variant="gradient"
            buttonColor="warning"
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              setIsShowModalBooking(true);
            }}
          >
            <Icon>edit</Icon>
          </SuiButton>
        </SuiBox>
      </CardActions>
      <SuiBox />
      <Collapse in={expanded} timeout="auto">
        <CardContent
          sx={{
            px: {
              xs: 2,
              md: 3,
            },
            py: {
              xs: '1rem!important',
              md: '1.5rem!important',
            },
          }}
        >
          <SuiBox
            display="flex"
            flexDirection={{
              xs: 'column',
              md: 'row',
            }}
            flexWrap="wrap"
            gap={2}
          >
            {booking?.orders?.map((order) => (
              <SuiBox
                key={order._id}
                display="flex"
                borderRadius="lg"
                width="100%"
              >
                <OrderHistory
                  order={order}
                  bill={booking?.bills?.find(
                    (bill) => String(bill.order).toString() === order._id
                  )}
                  handleOpenOrderForm={() => {
                    setSelectedOrder(order);
                    setIsShowModalOrder(true);
                  }}
                />
              </SuiBox>
            ))}
          </SuiBox>
        </CardContent>
      </Collapse>
      {isShowModalBooking && (
        <BookingForm
          open
          booking={booking}
          onClose={() => setIsShowModalBooking(false)}
          onSuccess={() => setIsShowModalBooking(false)}
        />
      )}
      {isShowModalOrder && selectedOrder && (
        <OrderForm
          onClose={() => {
            setSelectedOrder(undefined);
            setIsShowModalOrder(false);
          }}
          orderId={selectedOrder._id}
          onOpenBookingForm={() => {
            setIsShowModalOrder(false);
            setSelectedOrder(undefined);
            setIsShowModalBooking(true);
          }}
        />
      )}
    </Card>
  );
}

export default BookingHistory;
